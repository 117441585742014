<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      信息市场
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;padding-bottom:40px;background-color: white">
      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              发布信息
            </el-button>
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;text-align: left;">
          <div v-if="squareList && squareList.length>0" style="width: 100%;float: left;">
            <div v-for="item in squareList"  style="width: 100%;float: left;    border-bottom: 1px solid #8080804f;padding-bottom: 35px;margin-bottom: 10px;">
              <div v-if="item" style="width: 100%;float: left;">
                <div
                  style="width: 100%;float: left;    height: 40px;line-height: 40px;font-size: 1.1rem;font-weight: 600;color: #25ad7f;">
                  <span style="color: #02a7f099"> {{ item.merchantVO && item.merchantVO.merchantDetailVO ? item.merchantVO.merchantDetailVO.companyName : "" }}</span>
                  {{ item.userVO ? item.userVO.no : "" }}
                  <el-tag style="margin-right: 10px" type="success">{{ item.typeStr }}</el-tag>
                  <el-tag style="margin-right: 10px" v-if=" item.isLock == 1" closable type="info">该信息已被平台管理员禁用</el-tag>
                </div>
                <div style="width: 100%;float: left;    margin: 10px 0px;font-size: 0.9rem">
                  内容： {{ item.context }}
                </div>
                <div v-if="item.squareImageListGuid&&item.squareImageListGuid.length>0"
                     v-for="guid in item.squareImageListGuid"
                     style="height: 100px;float: left;margin-right: 20px;margin-bottom: 20px">
                  <el-image v-if="guid" title="点击放大"
                            style="width: auto; height: 100%"
                            :src="'/admin/web/file/downloadFile?id='+guid"
                            :preview-src-list="['/admin/web/file/downloadFile?id='+guid]">
                  </el-image>

                </div>
                <div style="width: 100%;float: left;color: gray;opacity: 0.9;font-size: 0.95rem;">
                  <div style="float:left;margin-right: 30px;">
                    {{item.createDateStr}}
                  </div>
                  <div style="float:left;margin-right: 30px;cursor: pointer">
                    <i class="el-icon-view" style="margin-right: 10px" />{{ item.browseCount ? item.browseCount : 0 }}
                  </div>
                  <div style="float:left;margin-right: 30px;cursor: pointer" @click="clickComment(item)">
                    <i class="el-icon-chat-line-round"
                       style="margin-right: 10px" />{{ item.commentCount ? item.commentCount : 0 }}
                  </div>
                  <div style="float:left;margin-right: 30px;cursor: pointer">
                    <i class="el-icon-thumb" style="margin-right: 10px" />{{ item.likeCount ? item.likeCount : 0 }}
                  </div>
                  <div style="float:left;margin-right: 30px;cursor: pointer">
                    <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消" @confirm="deleteSubmit(item)">
                      <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                                 type="primary">
                        <a-icon type="minus-circle" />
                        删除信息
                      </el-button>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty description="还没有发布信息">
              <el-button style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
                去发布
              </el-button>
            </el-empty>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="修改菜单" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width: 100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              信息类型 <span style="color: red">*</span>
            </template>
            <el-select size="mini" style="float: left;" v-model="insertForm.type" clearable filterable>
              <el-option v-for="item in squareTypeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item>
            <template slot="label">
              文字描述 <span style="color: red">*</span>
            </template>
            <el-input type="textarea" :rows="3" style="width:85%;float: left;" placeholder="请输入内容,0/500" oninput="if(value.length>499)value=value.slice(0,499)"
                      v-model="insertForm.context">
            </el-input>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>

          <el-form-item>
            <template slot="label">
              图片（9张） <span style="color: red"></span>
            </template>
            <el-upload :limit=9 action="#" list-type="picture-card" :multiple="true" :on-change="changeSquareImage"
                       :file-list="squareImageFileList" :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="squareImageShowDialog(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete" @click="removeSquareImage(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
            <!-- 大图显示 -->
            <el-dialog :visible.sync="squareImageShow">
              <img width="100%" :src="squareImageShowUrl" alt="">
            </el-dialog>
          </el-form-item>


        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>

    <el-drawer :title="commentData.context" :visible.sync="commentShow" direction="rtl">
      <div style="padding: 20px 20px;text-align: left;font-size: 0.8rem;width: 100%;float: left;border-top: 1px solid #8080804d;" v-if="commentData.squareCommentVOList&&commentData.squareCommentVOList.length>0" v-for="squareComment in commentData.squareCommentVOList">
        <div style="width: 100%;float: left;    height: 30px;
    color: gray;" >
          <span>{{squareComment&&squareComment.userVO?squareComment.userVO.name:''}}</span>
          <span style="margin-left: 30px">{{parseDate(squareComment?squareComment.createDate:'')}}</span>
        </div>
        <div style="width: 100%;float: left;font-size: 0.9rem" >
          {{squareComment?squareComment.context:''}}
        </div>
      </div>
      <el-empty  v-if="!(commentData.squareCommentVOList&&commentData.squareCommentVOList.length>0)"  description="暂无评论"></el-empty>
    </el-drawer>

  </div>
</template>

<script>

import { listSquareTypeApi } from "@/router/api/common/data-api";

import { listMerchantSquareApi,updateIsDelApi } from "@/router/api/square/square-api";

export default {
  name: "MerchantSquare",
  data() {
    return {

      squareTypeList: [],
      squareList: [],


      commentShow: false,
      commentData: false,

      //新增
      insertDialog: false,
      insertForm: {
        context: null,
        type: null
      },
      squareImageFileList: [],
      squareImageShow: false,//是否显示大图
      squareImageShowUrl: ""//大图的url
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      listSquareTypeApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.squareTypeList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.listMerchantSquare();

    },
    // 查询 begin
    listMerchantSquare(showMessage) {
      let params = {};
      listMerchantSquareApi(params, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.squareList = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    clickComment(item) {
      this.commentShow = true;
      this.commentData = item;
    },

    // 查询 end


    // 新增 begin

    //身份证上传
    changeSquareImage(file, fileList) {
      this.squareImageFileList = fileList;
    },

    removeSquareImage(file) {
      for (let item in this.squareImageFileList) {
        if (item && this.squareImageFileList[item] && file && this.squareImageFileList[item].uid == file.uid) {
          this.squareImageFileList.splice(item, 1);
        }
      }
    },

    squareImageShowDialog(e) {
      this.squareImageShowUrl = e.url;
      this.squareImageShow = true;
    },

    insertShow() {
      this.insertDialog = true;

    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        context: null,
        type: null
      };
      this.squareImageFileList = [];
      this.squareImageShow = false;//是否显示大图
      this.squareImageShowUrl = "";//大图的url

    },

    insertSubmit() {


      if (!this.insertForm.type) {
        this.showMessage("请选择类型", "error");
        return false
      }

      if (!this.insertForm.context) {
        this.showMessage("请填写内容", "error");
        return false
      }

      //数据组装
      let params = new FormData();
      params.append("context", this.insertForm.context);
      params.append("type", this.insertForm.type);


      if (this.squareImageFileList && this.squareImageFileList.length > 0) {
        for (let i = 0; i < this.squareImageFileList.length; i++) {
          let temp = this.squareImageFileList[i].raw;
          params.append("squareImageMultipartFile[]", temp);
        }
      }

      let url = "/admin/web/square/insertSquare";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.listMerchantSquare();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    // 新增 end


    // 删除 begin
    deleteSubmit(data){
      if(!data&&!data.id){
        this.showMessage("请选择需要删除的信息", "warning");
        return false;
      }
      updateIsDelApi({ "id":data.id }, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.listMerchantSquare();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 删除 end
    // 工具


    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }


  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
