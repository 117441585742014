<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      知识中心>视频课程
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">
      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">
            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle" />
              新增课程
            </el-button>
            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                          @confirm="deleteKnowledgeBatch()">
              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                         type="primary">
                <a-icon type="minus-circle" />
                批量删除
              </el-button>
            </a-popconfirm>
          </div>
        </div>
        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-table element-loading-text="加载中..." default-expand-all align="center"
                    @selection-change="handleSelectionChange" height="600" :data="tableData"
                    style="color: #555;" size="mini">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table size="mini" empty-text="请添加视频" v-if="props.row.knowledgeVideoVOList"
                          v-bind:data="props.row.knowledgeVideoVOList" :default-expand-all="true" stripe
                          :show-header="true" class="childTable">
                  <el-table-column align="center" prop="name" label="视频标题" min-width="20%"></el-table-column>
                  <el-table-column align="center" prop="duration" label="时长" min-width="20%"></el-table-column>
                  <el-table-column align="center" label="视频内容" min-width="20%">
                    <template slot-scope="scope">
                      <video style="height: 80px;width: 150px;" controls
                             :src="'/admin/web/file/downloadFile?id='+scope.row.videoGuid" />
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="播放量" min-width="20%">
                    <template slot-scope="scope">
                      {{ scope.row.viewCount ? scope.row.viewCount : "0" }}次
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="viewCount" label="用户评价" min-width="20%">
                    <template slot-scope="scope">
                      {{ scope.row.knowledgeVideoCommentVOList ? scope.row.knowledgeVideoCommentVOList : "0" }}条
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="操作" min-width="20%">
                    <template slot-scope="scope">

                      <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                            @click="updateVideoShow(scope.row)">修改</span>
                      <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                                    @confirm="deleteVideo(scope.row)">
                        <span style="margin:0px 5px">|</span>
                        <span style="font-size:0.9rem;color:#e32e2e;cursor: pointer;">删除</span>
                      </a-popconfirm>
                    </template>
                  </el-table-column>

                </el-table>
              </template>
            </el-table-column>


            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="context" label="课程介绍" min-width="20%"></el-table-column>
            <el-table-column align="center" label="一级类型" min-width="20%">
              <template slot-scope="scope">
                {{ scope.row.firstTypeVO ? scope.row.firstTypeVO.name : "" }}
              </template>
            </el-table-column>

            <el-table-column align="center" label="创建时间" min-width="20%">
              <template slot-scope="scope">
                {{ parseDate(scope.row.createDate ? scope.row.createDate : "") }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="20%">
              <template slot-scope="scope">

                <span style="font-size:0.9rem;cursor: pointer;color: #25AD7F"
                      @click="insertVideoShow(scope.row)">添加视频</span>
                <span style="margin:0px 5px">|</span>
                <span style="font-size:0.9rem;cursor: pointer;color: #409EFF"
                      @click="updateShow(scope.row)">修改</span>
                <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消"
                              @confirm="deleteKnowledge(scope.row)">
                  <span style="margin:0px 5px">|</span>
                  <span style="font-size:0.9rem;color:#e32e2e;cursor: pointer;">删除</span>
                </a-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div style="width: 100%;float: right;margin-top: 10px;text-align: right;">
            <el-pagination
              @current-change="handleCurrentChange"
              :page-size="selectForm.pageSize"
              :current-page="selectForm.pageIndex"
              layout="total,prev, pager, next"
              :total="selectForm.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增课程" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              课程介绍 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.context"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              知识类型 <span style="color: red">*</span>
            </template>
            <el-cascader v-model="insertType" clearable :options="treeKnowledgeType" :props="defaultProps"
                         @change="knowledgeTypeInsertChange"></el-cascader>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>




    <!-- 修改 -->
    <el-dialog title="修改课程" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">

          <el-form-item style="">
            <template slot="label">
              课程介绍 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.context"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              知识类型 <span style="color: red">*</span>
            </template>
            <el-cascader v-model="updateType"  clearable :options="treeKnowledgeType" :props="defaultProps"
                         @change="knowledgeTypeUpdateChange"></el-cascader>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>



    <el-dialog title="新增知识视频" :visible.sync="insertVideoDialog" width="40%" :before-close="insertVideoCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              视频标题 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertVideoForm.name"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              上传视频 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="changeCompanyIdCard"
                       :show-file-list="false" :multiple="false" :auto-upload="false">
              <video @loadedmetadata="getInsertVideoInfo"    style="width: 148px;height: 148px;" v-if="mainImageFile" :src="mainImageFile.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertVideoSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertVideoCancel">取消</el-button>
      </div>
    </el-dialog>


    <el-dialog title="修改知识视频" :visible.sync="updateVideoDialog" width="40%" :before-close="updateVideoCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item style="">
            <template slot="label">
              视频标题 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateVideoForm.name"
                      oninput="if(value.length>500)value=value.slice(0,500)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
          <el-form-item style="">
            <template slot="label">
              上传视频 <span style="color: red">*</span>
            </template>
            <el-upload action="#" list-type="picture-card" :on-change="updateChangeCompanyIdCard"  :show-file-list="false" :multiple="false" :auto-upload="false">
              <video @loadedmetadata="getUpdateVideoInfo" style="width: 148px;height: 148px;" v-if="updateMainImageFileUrl" :src="updateMainImageFileUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateVideoSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateVideoCancel">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>


import { treeAllKnowledgeTypeApi } from "@/router/api/knowledge/knowledge-type-api";
import {
  pageAllKnowledgeApi,
  deleteKnowledgeApi,
  updateKnowledgeApi,
  insertKnowledgeApi, deleteKnowledgeVideoApi
} from "@/router/api/knowledge/knowledge-api";

export default {
  name: "MerchantProduct",
  data() {
    return {

      treeKnowledgeType: [],
      defaultProps: {
        children: "childrenKnowledgeType",
        label: "name",
        value: "id"
      },

      // 查询
      tableData: [],
      selectForm: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      selectTableRow: [],

      //新增
      insertDialog: false,
      insertForm: {
        context: null,
        firstType: null,
        secondType: null,
      },
      insertType: [],

      //修改
      updateDialog: false,
      updateForm: {
        id: null,
        context: null,
        firstType: null,
        secondType: null,
      },
      updateType: [],

      //  视频
      insertVideoDialog: false,
      insertVideoForm: {
        name: null,
        duration:null,
      },
      mainImageFile: null,
      insertVideoLoading:false,


      updateVideoDialog: false,
      updateVideoForm: {
        id: null,
        name: null,
        duration:null,
      },
      updateVideoLoading:false,
      updateMainImageFile: null,
      updateMainImageFileUrl: null,


    };
  },

  mounted() {
    this.load();
  },

  methods: {

    load() {
      this.treeAllKnowledgeType();
      this.pageAllKnowledge();
    },

    //新增视频  begin

    changeCompanyIdCard(file) {
      this.mainImageFile = file;
    },

    insertVideoShow(data) {
      this.insertVideoDialog = true;
      this.insertVideoForm.knowledgeId = data.id;
    },

    insertVideoCancel() {
      this.insertVideoDialog = false;
      this.insertVideoForm = {
        knowledgeId: null,
        name: null,
        duration: null
      };
      this.mainImageFile = null;
    },

    insertVideoSubmit() {
      //TODO 非空判断
      if (!this.insertVideoForm.name) {
        this.showMessage("请填写视频标题", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("knowledgeId", this.insertVideoForm.knowledgeId);
      params.append("name", this.insertVideoForm.name);
      params.append("duration", this.insertVideoForm.duration);

      if (this.mainImageFile) {
        params.append("videoMultipartFile", this.mainImageFile.raw);
      } else {
        this.showMessage("请上传视频", "error");
        return false;
      }

      let url = "/admin/web/knowledgeVideo/insertKnowledgeVideo";
      if(this.insertVideoLoading){
        this.showMessage("上传中，请稍后", "warning");
        return false;
      }
      this.insertVideoLoading = true;
      this.alexPost(url, params, res => {
        this.insertVideoLoading = false;
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertVideoCancel();
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    getInsertVideoInfo(e){
      this.insertVideoForm.duration = this.convertSecondsToMinutesSeconds(parseInt(e.currentTarget.duration))
    },

    //新增视频  end

    //修改视频  begin


    updateVideoShow(data) {
      this.updateVideoDialog = true;
      this.updateVideoForm = {
        id: data.id,
        name: data.name,
      };
      this.updateMainImageFileUrl = "/admin/web/file/downloadFile?id=" + data.videoGuid;//缩略图返显 上传
    },

    updateVideoCancel() {
      this.updateVideoDialog = false;
      this.updateVideoForm = {
        id: null,
        name: null,
        duration: null

      };
      this.updateMainImageFile = null;
      this.updateMainImageFileUrl = null; //返现
    },

    // 营业执照上传
    updateChangeCompanyIdCard(file) {
      this.updateMainImageFile = file;
      this.updateMainImageFileUrl = file.url;
    },


    getUpdateVideoInfo(e){
      this.updateVideoForm.duration = this.convertSecondsToMinutesSeconds(parseInt(e.currentTarget.duration));
    },


    updateVideoSubmit() {

      //TODO 非空判断
      if (!this.updateVideoForm.name) {
        this.showMessage("请填写视频标题", "error");
        return false;
      }

      //数据组装
      let params = new FormData();
      params.append("id", this.updateVideoForm.id);
      params.append("name", this.updateVideoForm.name);
      params.append("duration", this.updateVideoForm.duration);

      if (this.updateMainImageFile) {
        params.append("videoMultipartFile", this.updateMainImageFile.raw);
      }

      if(this.updateVideoLoading){
        this.showMessage("上传中，请稍后", "warning");
        return false;
      }
      this.updateVideoLoading = true;
      let url = "/admin/web/knowledgeVideo/updateKnowledgeVideo";
      this.alexPost(url, params, res => {
        this.updateVideoLoading = false;
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateVideoCancel();
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });
    },

    //修改视频  end

    //删除视频 begin

    // 删除 begin
    deleteVideo(item) {
      if (!item) {
        this.showMessage("请选择需要删除的视频", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteKnowledgeVideoApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },
    //删除视频 end



    // 查询 begin
    selectReset() {
      this.selectForm = {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      };
      this.pageAllKnowledge(true);
    },

    handleCurrentChange(e) {
      this.selectForm.pageIndex = e;
      this.pageAllKnowledge(true);
    },

    handleSelectionChange(val) {
      this.selectTableRow = val;
    },

    pageAllKnowledge(showMessage) {
      pageAllKnowledgeApi(this.selectForm, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.tableData = res.data.resultSetData.list;
              this.selectForm.pageIndex = res.data.resultSetData.pageNum;
              this.selectForm.pageSize = res.data.resultSetData.pageSize;
              this.selectForm.total = res.data.resultSetData.total;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    treeAllKnowledgeType() {
      let params = {};
      treeAllKnowledgeTypeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.treeKnowledgeType = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 查询 end


    // 新增 begin

    insertShow() {
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        context: null,
        firstType: null,
        secondType: null
      };
      this.insertType = [];
    },


    knowledgeTypeInsertChange(e) {
      this.insertType = e;
      if (e.length == 0) {
        this.insertForm.firstType = null;
        this.insertForm.secondType = null;
      } else {
        this.insertForm.firstType = e[0];
        this.insertForm.secondType = e[1];
      }
    },


    insertSubmit() {

      //TODO 非空判断
      if (!this.insertForm.context) {
        this.showMessage("请填写课程介绍", "error");
        return false;
      }
      if (!this.insertForm.firstType) {
        this.showMessage("请选择知识类型", "error");
        return false;
      }
      let params = this.insertForm;
      insertKnowledgeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.insertCancel();
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },
    // 新增 end

    // 删除 begin
    deleteKnowledge(item) {
      if (!item) {
        this.showMessage("请选择需要删除的课程", "warning");
        return false;
      }
      let params = [
        {
          "id": item.id
        }
      ];
      deleteKnowledgeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    deleteKnowledgeBatch() {

      if (!this.selectTableRow || this.selectTableRow.length == 0) {
        this.showMessage("请勾选需要需要删除的课程", "warning");
        return false;
      }

      let params = [];
      for (let i = 0; i < this.selectTableRow.length; i++) {
        let row = this.selectTableRow[i];
        if (row) {
          params.push({
            "id": row.id
          });
        }
      }

      deleteKnowledgeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });


    },

    // 删除 end

    // 修改 begin


    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        context: data.context,
        firstType: data.firstType,
        secondType: data.secondType
      };
      this.updateType.push(data.firstType);
      this.updateType.push(data.secondType);

    },


    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        context: null,
        firstType: null,
        secondType: null
      };
      this.updateType = [];
    },


    knowledgeTypeUpdateChange(e) {
      this.updateType = e;
      if (e.length == 0) {
        this.updateForm.firstType = null;
        this.updateForm.secondType = null;
      } else {
        this.updateForm.firstType = e[0];
        this.updateForm.secondType = e[1];
      }
    },


    updateSubmit() {

      //TODO 非空判断
      if (!this.updateForm.context) {
        this.showMessage("请填写课程介绍", "error");
        return false;
      }
      if (!this.updateForm.firstType) {
        this.showMessage("请选择知识类型", "error");
        return false;
      }

      let params = this.updateForm;
      updateKnowledgeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.updateCancel();
              this.pageAllKnowledge();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 修改 end

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    },

    convertSecondsToMinutesSeconds(seconds) {
      if(seconds){
        let minutes = Math.floor(seconds / 60); // 计算分钟
        let remainingSeconds = seconds % 60; // 计算剩余秒数
        // 如果需要，可以将分钟和秒数补充为两位数
        minutes = minutes < 10 ? '0' + minutes : minutes;
        remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
        // 返回格式化的时间字符串
        return minutes + '分' + remainingSeconds + '秒';
      }else{
        return null;
      }

    }
  }


}
;

</script>


<style scoped>

/deep/ .el-card__body, .el-main {
  float: left;
  width: 100%;
}

/*  通用 */
.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .numberInput input[type="number"] {
  -moz-appearance: textfield;
}

</style>
