<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      平台首页
    </div>


    <div style="width:100%;height:auto;float:left;border-radius: 15px;padding-bottom:40px;">
      <!--   四个logo -->
      <div style="width: 100%;float: left;margin-bottom: 25px" v-if="orderCount">
        <div style="width:31.25%;float: left; ">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="今天订单量" :value="orderCount?orderCount.todayOrderCount+'':'0'" unit="单"
                           trend="increase" color="red" />
              <t-icon name="task" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
        <div style="width:31.25%;float: left;margin-left: 3%; ">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="总订单量" :value="orderCount?orderCount.allOrderCount+'':'0'" unit="单"
                           trend="increase" color="red" />
              <t-icon name="task" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
        <div style="width:31.25%;float: left; margin-left: 3%;">
          <el-card class="box-card" style="cursor: pointer">
            <t-space align="center">
              <t-statistic title="总成交额" :value="orderCount&&orderCount.allPayMoney?orderCount.allPayMoney+'':'0.00'"
                           unit="￥"
                           trend="increase" color="red" />
              <t-icon name="money" class="icon"
                      style="font-size: 3rem;background-color:#E1F0EC;padding:10px;border-radius:100px;color: #37b48a" />
            </t-space>
          </el-card>
        </div>
      </div>

      <!--    echarts -->
      <div
        style="width:100%;height:auto;float:left;border-radius: 15px;margin-right: 3%;background-color:white;padding: 20px;">
        <div ref="taskSumCharts" style="width: 100%;min-height: 560px;float: left;"></div>
      </div>

      <div
        style="width:48.5%;height:auto;float:left;border-radius: 15px;margin-right: 3%;background-color:white;padding: 20px;margin-top: 25px;">
        <div ref="productServiceCharts" style="width: 100%;min-height: 260px;float: left;"></div>
      </div>
      <div
        style="width:48.5%;height:auto;float:left;border-radius: 15px;background-color:white;padding: 20px;margin-top: 25px;">
        <div ref="workflowCharts" style="width: 100%;min-height: 260px;float: left;"></div>
      </div>
    </div>

  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  countAllOrderApi, countMoneyAllOrderApi, countProductServiceAllOrderApi, countWorkflowDetailAllOrderApi
} from "@/router/api/order/order-api";

import { countMerchantSettlementMoneyApi } from "@/router/api/order/order-settlement-api";

export default {
  name: "Index",
  data() {
    return {
      orderCount: null,


      settlementMoney: 0.00,

      // 折线图
      taskSumCharts: null,
      orderMoneyList: [],
      dayList: [],
      orderDayList: [],

      productServiceCharts: null,
      productServiceList: [],
      productValue: 0,
      serviceValue: 0,

      //流程服务
      workflowCharts: null,
      workflowDetail: [],
      workflowDetailTitle: [],
      workflowDetailValue: []


    };
  },

  mounted() {
    this.load();
  },

  methods: {

    load() {

      countAllOrderApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.orderCount = res.data.resultSetData;
              debugger;
              console.info(this.orderCount);
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      countMerchantSettlementMoneyApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              let settlementMoney = res.data.resultSetData;
              if (settlementMoney && settlementMoney.value) {
                this.settlementMoney = settlementMoney.value;
                if (this.settlementMoney) {
                  this.settlementMoney = (this.settlementMoney * 0.994).toFixed(2);
                }
              }
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

      this.countMoneyMerchantOrder();

      this.countProductServiceOrder();

      this.countWorkflowDetailOrder();


    },

    countMoneyMerchantOrder() {
      this.taskSumCharts = echarts.init(this.$refs.taskSumCharts);
      countMoneyAllOrderApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.orderMoneyList = res.data.resultSetData;
              if (this.orderMoneyList) {
                for (let i = 0; i < this.orderMoneyList.length; i++) {
                  let dataDay = this.orderMoneyList[i];
                  if (dataDay) {
                    this.dayList.push(dataDay.day);
                    this.orderDayList.push(dataDay.everyDayCount);
                  }
                }
              }
              this.loadMyOrderMoneyChants();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    countProductServiceOrder() {
      this.productServiceCharts = echarts.init(this.$refs.productServiceCharts);
      countProductServiceAllOrderApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.productServiceList = res.data.resultSetData;
              if (this.productServiceList) {
                for (let i = 0; i < this.productServiceList.length; i++) {
                  let productService = this.productServiceList[i];
                  if (productService && productService.key == "农产品") {
                    this.productValue = productService.value;
                  }
                  if (productService && productService.key == "农服务") {
                    this.serviceValue = productService.value;
                  }
                }
              }
              this.loadProductServiceChants();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    countWorkflowDetailOrder() {
      this.workflowCharts = echarts.init(this.$refs.workflowCharts);
      countWorkflowDetailAllOrderApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              let workflowDetailList = res.data.resultSetData;
              for (let i = 0; i < workflowDetailList.length; i++) {
                let workflowDetail = workflowDetailList[i];
                if (workflowDetail) {
                  this.workflowDetailTitle.push(workflowDetail.workflowTypeStr);
                  this.workflowDetailValue.push({
                    value: workflowDetail.count,
                    name: workflowDetail.workflowTypeStr
                  });

                }
              }
              this.loadWorkflowChants();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    loadMyOrderMoneyChants() {
      let option = {
        color: ["#E1F0EC"],
        title: {
          text: "本月订单量"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          data: ["订单曲线"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.dayList
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "订单量",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#37b48a"
                },
                {
                  offset: 1,
                  color: "#E1F0EC"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: this.orderDayList
          }

        ]
      };
      this.taskSumCharts.clear();
      this.taskSumCharts.setOption(option);
    },

    loadProductServiceChants() {

      let option = {
        title: {
          text: "农产品与农服务比例",
          subtext: "",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["农产品", "农服务"]
        },
        series: [
          {
            name: "订单数据",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              {
                value: this.productValue,
                name: "农产品",
                itemStyle: {
                  color: "#37b48a"
                }
              },
              {
                value: this.serviceValue,
                name: "农服务",
                itemStyle: {
                  color: "#e1f0ec"
                }
              }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      this.productServiceCharts.clear();
      this.productServiceCharts.setOption(option);
    },

    loadWorkflowChants() {

      let option = {
        title: {
          text: "农服务子流程比例",
          subtext: "",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.workflowDetailTitle
        },
        series: [
          {
            name: "订单数据",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.workflowDetailValue,
            // data: [
            //   {
            //     value: 335,
            //     name: "农产品"
            //   },
            //   {
            //     value: 310, name: "农服务"
            //   }
            // ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      this.workflowCharts.clear();
      this.workflowCharts.setOption(option);
    },


    gotoOrderPage() {
      this.$router.push("/merchantOrder");
    },

    // 工具

    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }


  }

};

</script>


<style scoped>


</style>
