<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      商户设置>基础信息
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">

        <div
          style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 1.1rem;font-weight: 600;color:#6bd36b">
          {{ merchantInfo && merchantInfo.merchantDetailVO && merchantInfo.merchantDetailVO.companyName ? merchantInfo.merchantDetailVO.companyName : ""
          }}
        </div>
        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-form label-width="15%" style="width: 100%;float:left;">
            <el-form-item style="">
              <template slot="label">
                商标
              </template>
              <el-upload action="#" list-type="picture-card" :on-change="updateChangeCompanyIdCard"
                         :show-file-list="false" :multiple="false" :auto-upload="false">
                <el-image ref="mainref" fit="contain" v-if="updateLogoMainImageFileUrl"
                          :src="updateLogoMainImageFileUrl"
                          class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="width: 15%;float: left;text-indent: 5px;">方形</div>
            </el-form-item>

            <el-form-item>
              <template slot="label">
                联系方式
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.tel" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>

            <el-form-item>
              <template slot="label">
                经营地址
              </template>
              <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.address" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>

            <el-form-item label="">
              <template slot="label">
                经营业务
              </template>
              <el-input size="mini" type="textarea" :rows="4" style="width:85%;float: left;"
                        v-model="updateForm.context" oninput="if(value.length>200)value=value.slice(0,200)" />
              <div style="width: 15%;float: left;text-indent: 5px;"></div>
            </el-form-item>
          </el-form>
        </div>
        <div class="dialog-footer" style="padding-left: 50px;">
          <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
          <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import { getMyMerchantApi } from "@/router/api/merchant/merchant-api";


export default {
  name: "MerchantInfo",
  data() {
    return {

      //查询
      merchantInfo: {},
      updateForm: {
        tel: null,
        address: null,
        context: null
      },

      updateLogoMainImageFile: null,
      updateLogoMainImageFileUrl: null
    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.getMyMerchant();
    },


    getMyMerchant(showMessage) {
      //查询前先重置
      this.merchantInfo = {};
      getMyMerchantApi({}, res => {
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              if (showMessage) {
                this.showMessage(res.data.msg, "success");
              }
              this.merchantInfo = res.data.resultSetData;
              if (this.merchantInfo) {
                this.updateLogoMainImageFileUrl = "/admin/web/file/downloadFile?id=" + this.merchantInfo.logoImageGuid;//缩略图返显 上传
                this.updateForm.tel = this.merchantInfo.tel;
                this.updateForm.address = this.merchantInfo.address;
                this.updateForm.context = this.merchantInfo.context;
              }
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },


    // 营业执照上传
    updateChangeCompanyIdCard(file) {

      let thizz = this;
      thizz.updateLogoMainImageFile = file;
      thizz.updateLogoMainImageFileUrl = file.url;





    },


    updateSubmit() {


      //数据组装
      let params = new FormData();

      params.append("id", this.merchantInfo.id);
      if (this.updateForm.tel) {
        params.append("tel", this.updateForm.tel);
      } else {
        params.append("tel", null);
      }
      if (this.updateForm.address) {
        params.append("address", this.updateForm.address);
      } else {
        params.append("address", null);
      }
      if (this.updateForm.context) {
        params.append("context", this.updateForm.context);
      } else {
        params.append("context", null);

      }

      if (this.updateLogoMainImageFile) {
        params.append("logoImageMultipartFile", this.updateLogoMainImageFile.raw);
      }

      let url = "/admin/web/merchant/updateMerchantInfo";
      this.alexPost(url, params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.getMyMerchant();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "token": localStorage.getItem("token")
        }
      });


    },

    updateCancel() {
      this.getMyMerchant();

    },

    // 工具
    parseDate(date) {
      if (date) {
        return new Date(date).toLocaleString();
      }
      return "";
    }
  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}

</style>
