import Vue from "vue";

export function getAlexBoolListApi(params, success) {
  let url = "/admin/web/data/getAlexBoolList";
  Vue.prototype.alexPost(url, params, success);
}


export function listPlatformApi(params, success) {
  let url = "/admin/web/data/listPlatform";
  Vue.prototype.alexPost(url, params, success);
}


export function listApproveStatusApi(params, success) {
  let url = "/admin/web/data/listApproveStatus";
  Vue.prototype.alexPost(url, params, success);
}

export function listApproveTypeApi(params, success) {
  let url = "/admin/web/data/listApproveType";
  Vue.prototype.alexPost(url, params, success);
}

export function listSysLogBusinessApi(params, success) {
  let url = "/admin/web/data/listSysLogBusiness";
  Vue.prototype.alexPost(url, params, success);
}

export function listSysLogLevelApi(params, success) {
  let url = "/admin/web/data/listSysLogLevel";
  Vue.prototype.alexPost(url, params, success);
}

export function listSquareTypeApi(params, success) {
  let url = "/admin/web/data/listSquareType";
  Vue.prototype.alexPost(url, params, success);
}

export function listIsOnlineApi(params, success) {
  let url = "/admin/web/data/listIsOnline";
  Vue.prototype.alexPost(url, params, success);
}

export function listWorkflowServiceTypeApi(params, success) {
  let url = "/admin/web/data/listWorkflowServiceType";
  Vue.prototype.alexPost(url, params, success);
}

export function listGoodsTypeApi(params, success) {
  let url = "/admin/web/data/listGoodsType";
  Vue.prototype.alexPost(url, params, success);
}

export function listProductTypeApi(params, success) {
  let url = "/admin/web/data/listProductType";
  Vue.prototype.alexPost(url, params, success);
}

export function listWorkflowTypeApi(params, success) {
  let url = "/admin/web/data/listWorkflowType";
  Vue.prototype.alexPost(url, params, success);
}

export function listMedicineTypeApi(params, success) {
  let url = "/admin/web/data/listMedicineType";
  Vue.prototype.alexPost(url, params, success);
}


export function listExpressCompanyApi(params, success) {
  let url = "/admin/web/data/listExpressCompany";
  Vue.prototype.alexPost(url, params, success);
}

export function listPayMethodApi(params, success) {
  let url = "/admin/web/data/listPayMethod";
  Vue.prototype.alexPost(url, params, success);
}






