<template>
  <div class="full-container">
    <div
      style="width: 100%;float: left;height: 50px;line-height: 50px;font-size: 0.9rem; text-align: left;opacity: 0.9;">
      知识中心>知识类型
    </div>
    <div style="width:100%;height:auto;float:left;border-radius: 15px;background-color: white;padding-bottom:40px;">

      <div style="width:100%;float:left;margin-top: 10px;">
        <!-- 查询按钮区 -->
        <div style="width: 100%;height: 60px;float: left;line-height: 60px;padding-left: 15px">
          <!-- 右边按钮 -->
          <div style="width: auto;float: right;margin-right: 20px">

            <el-button size="mini" style="background-color: #25AD7F;border:none;" type="primary" @click="insertShow()">
              <a-icon type="plus-circle"  />
              新增知识类型
            </el-button>

            <a-popconfirm title="是否删除?(敏感操作!)" ok-text="确定" cancel-text="取消" @confirm="deleteKnowledgeTypeBatch()">
              <el-button size="mini" style="background-color: #e32e2e1a;color:#e32e2e;border:1px solid #e32e2e;"
                         type="primary">
                <a-icon type="minus-circle" />
                批量删除
              </el-button>
            </a-popconfirm>
          </div>
        </div>

        <!-- 表单内容 -->
        <div style="width:100%;float:left;padding: 15px;">
          <el-tree ref="tree" :data="treeKnowledgeType" show-checkbox :props="defaultProps" label="name" node-key="id"
                   default-expand-all :expand-on-click-node="false">
            <div style="width: 100%;float: left" slot-scope="{ node, data }">
              <div style="width: 50%;float: left;text-align: left;text-indent: 5px;letter-spacing: 3px;">
                <span>{{ node.label }}</span>
              </div>
              <div style="width: 50%;float: left;text-align: right">

<!--                <el-button v-if="!data.parentId" style="color:#25AD7F" type="text" size="mini"-->
<!--                           @click="() => insertShow(data)">-->
<!--                  新增二级知识类型-->
<!--                </el-button>-->
                <el-button type="text" style="color: #409EFF" size="mini" @click="() => updateShow(data)">
                  修改
                </el-button>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
    </div>


    <el-dialog :title="insertTitle" :visible.sync="insertDialog" width="40%" :before-close="insertCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width:100%;float:left;">
          <el-form-item>
            <template slot="label">
              名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="insertForm.name"  oninput="if(value.length>35)value=value.slice(0,35)"/>
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="insertSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="insertCancel">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改知识类型" :visible.sync="updateDialog" width="40%" :before-close="updateCancel">
      <div style="width: 100%;float: left">
        <el-form label-width="15%" style="width: 100%;float:left;">


          <el-form-item>
            <template slot="label">
              知识类型名称 <span style="color: red">*</span>
            </template>
            <el-input size="mini" style="width:85%;float: left;" v-model="updateForm.name"  oninput="if(value.length>35)value=value.slice(0,35)" />
            <div style="width: 15%;float: left;text-indent: 5px;"></div>
          </el-form-item>
        </el-form>

      </div>
      <div class="dialog-footer">
        <el-button class="dialog-footer-submit" @click="updateSubmit">提交</el-button>
        <el-button class="dialog-footer-cancel" @click="updateCancel">取消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>


import {treeAllKnowledgeTypeApi,insertKnowledgeTypeApi,updateKnowledgeTypeApi,deleteKnowledgeTypeApi
} from "@/router/api/knowledge/knowledge-type-api";

export default {
  name: "KnowledgeType",
  data() {
    return {


      treeKnowledgeType: [],
      defaultProps: {
        children: "childrenKnowledgeType",
        label: "name"
      },

      //新增
      insertDialog: false,
      insertForm: {
        name: null,
      },
      insertTitle: null,

      //  修改
      updateDialog: false,
      updateForm: {
        id: null,
        name: null,
      },



    };
  },

  mounted() {

    this.load();


  },

  methods: {

    load() {
      this.treeAllKnowledgeType();



    },

    treeAllKnowledgeType() {
      let params = {};
      treeAllKnowledgeTypeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.treeKnowledgeType = res.data.resultSetData;
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 新增 begin

    insertShow(data) {
      if (data) {
        this.insertForm.parentId = data.id;
        this.insertTitle = "新增" + data.name + "二级知识类型";
      } else {
        this.insertForm.parentId = null;
        this.insertTitle = "新增知识类型";
      }
      this.insertDialog = true;
    },

    insertCancel() {
      this.insertDialog = false;
      this.insertForm = {
        platform: null,
        parentId: null,
        name: null,
        url: null,
        code: null,
        icon: null,
        sortNo: null
      };
      this.insertTitle = null;

    },

    insertSubmit() {


      if (!this.insertForm.name) {
        this.showMessage("请填写知识类型名称", "error");
        return false;
      }

      let params = this.insertForm;
      insertKnowledgeTypeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.treeAllKnowledgeType();
              this.insertCancel();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    // 新增 end

    // 删除 begin
    deleteKnowledgeTypeBatch() {
      let deleteList = this.$refs.tree.getCheckedNodes();
      if (deleteList == null || deleteList.length < 1) {
        this.showMessage("请选择需要删除的知识类型", "warning");
        return false;
      }
      let params = deleteList;
      deleteKnowledgeTypeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.treeAllKnowledgeType();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });
    },

    //  删除 end

    // 修改 begin

    updateShow(data) {
      this.updateDialog = true;
      this.updateForm = {
        id: data.id,
        platform: data.platform,
        parentId: data.parentId,
        name: data.name,
        url: data.url,
        code: data.code,
        icon: data.icon,
        sortNo: data.sortNo
      };
    },

    updateCancel() {
      this.updateDialog = false;
      this.updateForm = {
        id: null,
        platform: null,
        parentId: null,
        name: null,
        url: null,
        code: null,
        icon: null,
        sortNo: null
      };
    },

    updateSubmit() {

      if (!this.updateForm.name) {
        this.showMessage("请填写知识类型名称", "error");
        return false;
      }
      let params = this.updateForm;
      updateKnowledgeTypeApi(params, res => {
        //状态200
        if (res.status == "200") {
          if (res.data && res.data.status && res.data.msg) {
            if ("1" == res.data.status) {
              this.showMessage(res.data.msg, "success");
              this.treeAllKnowledgeType();
              this.updateCancel();
            } else {
              this.showMessage(res.data.msg, "error");
            }
          } else {
            this.showMessage("未知请求出错", "error");
          }
        } else {
          this.showMessage("未知请求出错！", "error");
        }
      });

    },

    // 修改 end




  }

};

</script>


<style scoped>

.dialog-footer {
  width: 100%;
  float: left;
  text-align: left;
}

.dialog-footer-submit {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-submit:hover {
  background-color: #25AD7F;
  color: white;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

.dialog-footer-cancel:hover {
  background-color: white;
  border: 1px solid #8080805c;
  color: #8080805c;
  width: 130px;
  border-radius: 50px;
  letter-spacing: 3px;
  text-align: center;
  font-weight: 100;
}

/* 树形菜单 */

/deep/ .el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

/* 表格 */

/deep/ .el-table th.el-table__cell {
  background-color: #F8F8F8;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #554 !important;
  font-weight: 600;
  font-size: 1.05rem;
}


/* 模态框 */

/deep/ .el-dialog__title {
  font-weight: 600;
}

/deep/ .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid darkgray;
}

/deep/ .el-dialog__body {
  width: 100%;
  float: left;
  background-color: white;
}

/deep/ .el-upload-dragger {
  height: auto;
}

/deep/ .el-form-item__content {
  text-align: left;
}


/* 纯数字 */
/deep/ .numberInput input::-webkit-outer-spin-button,
/deep/ .numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
}
/deep/.numberInput input[type="number"]{
  -moz-appearance: textfield;
}

</style>
